<template>
    <div>
      <ValidationObserver ref="formModalValidate">
        <b-row>
          <b-col cols="6" md="4" lg="6">
            <ValidationProvider name="level_id" rules="required" v-slot="{ errors }">
              <b-form-group :label="$t('level_id')">
                <EslLevelSelectbox
                    v-model="formData.level_id"
                />
                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="6" md="8" lg="6">
            <b-form-group :label="$t('name')">
              <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                <b-input-group class="mb-1">
                  <b-form-input v-model="formData.name" :state="errors[0] ? false : null"/>
                </b-input-group>
                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" md="4" lg="6">
            <ValidationProvider name="days_in_a_week" rules="required" v-slot="{ errors }">
              <b-form-group :label="$t('days_in_a_week')">
                <ParameterSelectbox
                    v-model="formData.days_in_a_week"
                    :multiple="true"
                    code="days_in_a_week"
                />
                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="6" md="8" lg="6">
            <ValidationProvider name="days_of_the_week" rules="required" v-slot="{ errors }">
              <b-form-group :label="$t('days_of_the_week')">

                <ParameterSelectbox
                    v-model="formData.days_of_the_week"
                    :multiple="true"
                    code="days_of_the_week"
                />
                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>

              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" md="4" lg="6">
            <ValidationProvider name="hours_in_a_day" rules="required" v-slot="{ errors }">
              <b-form-group :label="$t('hours_in_a_day')">
                <b-form-input type="number" v-model="formData.hours_in_a_day" :state="errors[0] ? false : null"/>
                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="6" md="8" lg="6">
            <b-form-group :label="$t('part_of_the_day')">
              <ValidationProvider name="part_of_the_day" rules="required" v-slot="{ errors }">
                <ParameterSelectbox
                    v-model="formData.part_of_the_day"
                    code="part_of_the_day"
                />
                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center mt-2">
          <b-button variant="primary" @click="updateForm" :disabled="formLoading">
            {{ $t('save') }}
          </b-button>
        </div>
      </ValidationObserver>
    </div>
</template>

<script>
// Components

// Services
import EslCourseService from "@/services/EslCourseService";

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import EslLevelSelectbox from "@/components/interactive-fields/EslLevelSelectbox.vue";

export default {
    components: {
      EslLevelSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                level_id: null,
                name: null,
                days_in_a_week: null,
                days_of_the_week: null,
                hours_in_a_day: null,
                part_of_the_day: null,

            },
            coordinatorValue: {},
            formLoading: false,
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            EslCourseService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        level_id: data.level_id,
                        name: data.name,
                        days_in_a_week: data.days_in_a_week,
                        days_of_the_week: data.days_of_the_week,
                        hours_in_a_day: data.hours_in_a_day,
                        part_of_the_day: data.part_of_the_day,

                    }
                    if (data.coordinator_id) {
                        this.coordinatorValue = {value: data.coordinator_id, text: data.coordinator_name}
                    }

                })
                .catch(e => {
                    this.showErrors(e)
                })
        },

        async updateForm() {
            if (this.checkPermission('course_update')) {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    EslCourseService.update(this.formId, this.formData)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
    }
}
</script>
